import { Context, Controller } from '@hotwired/stimulus';
import { Editor } from '@tiptap/core';
import Focus from '@tiptap/extension-focus';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Typography from '@tiptap/extension-typography';
import StarterKit from '@tiptap/starter-kit';
import { AttachmentUpload } from 'src/attachment_upload';
import { ActionTextChecklist } from 'src/tiptap/action_text_checklist';
import { ActionTextContentAttachment } from 'src/tiptap/action_text_content_attachment';
import { ActionTextImageAttachment } from 'src/tiptap/action_text_image_attachment';
import { ActionTextLabel } from 'src/tiptap/action_text_label';
import { ActionTextNonContentAttachment } from 'src/tiptap/action_text_non_content_attachment';
import { ActionTextUser } from 'src/tiptap/action_text_user';
import { DsFileUpload } from 'src/tiptap/ds_file_upload';
import { DsImageUpload } from 'src/tiptap/ds_image_upload';
import { DsInput, DsInputFactoryParams } from 'src/tiptap/ds_input';
import {
  toggleAttribute,
  toggleExpanded,
  toggleHidden,
} from 'src/utils/dom-toggle';
import { requestSubmit } from 'src/utils/form';

const DEBOUNCE_UPDATES = 500;

export default class TipTapController extends Controller {
  public static targets = ['editor', 'input', 'fallback'];
  public static values = {
    directUploadUrl: String,
    blobUrlTemplate: String,
    representationUrlTemplate: String,
    readonly: Boolean,
    interactive: Boolean,
  };

  private declare hasFallbackTarget: boolean;
  private declare hasEditorTarget: boolean;
  private declare hasInputTarget: boolean;

  private declare fallbackTarget: HTMLTextAreaElement;
  private declare editorTarget: HTMLElement;
  private declare inputTarget: HTMLInputElement;

  private declare hasReadonlyValue: boolean;
  private declare hasInteractiveValue: boolean;

  private declare directUploadUrlValue: string;
  private declare blobUrlTemplateValue: string;
  private declare representationUrlTemplateValue: string;
  private declare readonlyValue: boolean;
  private declare interactiveValue: boolean;

  private editor!: Editor;
  private fallback!: HTMLTextAreaElement;
  private input!: HTMLInputElement;
  private toolbar!: HTMLDivElement;
  private debounce: boolean = true;

  private onUpdateTimer: unknown | undefined;

  constructor(context: Context) {
    super(context);

    this.onUpdate = this.onUpdate.bind(this);
    this.onRequestSubmit = this.onRequestSubmit.bind(this);
  }

  connect(): void {
    if (!this.hasEditorTarget) {
      throw new Error('Mark-up for rich text is incorrect.');
    }

    if (this.hasInputTarget) {
      this.input = this.inputTarget!;
    } else {
      this.input = document.createElement('input');
      this.input.type = 'hidden';
      this.input.name = 'content';
      this.input.value = this.editorTarget.innerHTML || '';

      if (this.hasEditorTarget) {
        this.element.append(this.input);
      } else {
        this.element.parentElement!.append(this.input);
      }
    }

    console.debug('[tiptap] connect', this.input.value);

    let placeholder = 'Write something...';
    if (this.hasFallbackTarget) {
      this.fallback = this.fallbackTarget;
      this.fallbackTarget.remove();

      placeholder = this.fallback.placeholder;

      this.input.disabled = false;
    }

    const options: DsInputFactoryParams = {
      interactive: this.hasInteractiveValue ? this.interactiveValue : true,
    };

    const boundary = this.element.closest('[data-tiptap-boundary]');
    if (boundary) {
      options.autocompleteEmployeeUrl =
        boundary.getAttribute('data-tiptap-autocomplete-employee-url-value') ||
        undefined;
      options.autocompleteSiteUrl =
        boundary.getAttribute('data-tiptap-autocomplete-site-url-value') ||
        undefined;
    }

    this.editor = new Editor({
      element: this.editorTarget,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3, 4],
          },
        }),

        // Official plugins
        Focus,
        Highlight,
        Image.configure({ allowBase64: true }),
        Link,
        Placeholder.configure({ placeholder }),
        Typography,

        // Custom elements
        ActionTextChecklist,
        ActionTextContentAttachment,
        ActionTextImageAttachment,
        ActionTextLabel,
        ActionTextNonContentAttachment.extend({ priority: 50 }),
        ActionTextUser,
        DsInput(options),
        DsImageUpload((file) => {
          const uploader = document.createElement('div');
          uploader.setAttribute('role', 'progressbar');
          uploader.setAttribute('aria-valuemin', '0');
          uploader.setAttribute('aria-valuemax', '100');
          uploader.setAttribute('aria-valuenow', '0');
          uploader.setAttribute('aria-valuetext', 'Not started');
          uploader.classList.add(
            'absolute',
            'bottom-0',
            'left-0',
            'w-full',
            'bg-gray-200',
            'dark:bg-gray-800',
            'h-1',
            'overflow-hidden',
          );

          const bar = document.createElement('div');
          bar.classList.add(
            'bg-theme-primary-dark-500',
            'h-1',
            'w-full',
            'transition',
          );
          bar.style.transform = 'translateX(-100%)';

          uploader.append(bar);
          this.editorTarget.append(uploader);

          const upload = new AttachmentUpload(
            {
              file,
              setAttributes: console.debug,
              setUploadProgress: (progress, indeterminate) => {
                if (indeterminate) {
                  uploader.removeAttribute('aria-valuenow');
                  uploader.setAttribute('aria-valuetext', 'Uploading...');
                } else {
                  uploader.setAttribute(
                    'aria-valuenow',
                    String(Math.round(progress)),
                  );
                  uploader.setAttribute(
                    'aria-valuetext',
                    String(Math.round(progress)) + '%',
                  );

                  const x = 100 - progress;
                  bar.style.transform = `translateX(-${x}%)`;
                }

                if (progress === 100) {
                  uploader.remove();
                }
              },
            },
            this.directUploadUrlValue,
            this.blobUrlTemplateValue,
            this.representationUrlTemplateValue,
          );
          return upload.start();
        }),
        DsFileUpload((file) => {
          const uploader = document.createElement('div');
          uploader.setAttribute('role', 'progressbar');
          uploader.setAttribute('aria-valuemin', '0');
          uploader.setAttribute('aria-valuemax', '100');
          uploader.setAttribute('aria-valuenow', '0');
          uploader.setAttribute('aria-valuetext', 'Not started');
          uploader.classList.add(
            'absolute',
            'bottom-0',
            'left-0',
            'w-full',
            'bg-gray-200',
            'dark:bg-gray-800',
            'h-1',
            'overflow-hidden',
            'rounded',
          );

          const bar = document.createElement('div');
          bar.classList.add(
            'bg-theme-primary-dark-500',
            'h-1',
            'w-full',
            'transition',
          );
          bar.style.transform = 'translateX(-100%)';

          uploader.append(bar);
          this.editorTarget.append(uploader);

          const upload = new AttachmentUpload(
            {
              file,
              setAttributes: console.debug,
              setUploadProgress: (progress, indeterminate) => {
                if (indeterminate) {
                  uploader.removeAttribute('aria-valuenow');
                  uploader.setAttribute('aria-valuetext', 'Uploading...');
                } else {
                  uploader.setAttribute(
                    'aria-valuenow',
                    String(Math.round(progress)),
                  );
                  uploader.setAttribute(
                    'aria-valuetext',
                    String(Math.round(progress)) + '%',
                  );

                  const x = 100 - progress;
                  bar.style.transform = `translateX(-${x}%)`;
                }

                if (progress === 100) {
                  uploader.remove();
                }
              },
            },
            this.directUploadUrlValue,
            this.blobUrlTemplateValue,
            this.representationUrlTemplateValue,
          );
          return upload.start();
        }),
      ],
      editorProps: {
        attributes: {
          class: 'prose-sm', // lg:prose xl:prose-lg',
          // 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl m-5 focus:outline-none dark:prose-invert',
        },
      },
      content: this.input.value,
      onUpdate: this.onUpdate,
      injectCSS: false,
      editable: this.hasReadonlyValue ? !this.readonlyValue : true,
    });

    // Replace input when creation is done
    this.editor.on('create', (props) => {
      // debugger;
      this.input.value = props.editor.getHTML();
      console.debug('[tiptap] created', this.input.value);
    });

    this.inflate(this.editor);

    this.element
      .closest('form')
      ?.querySelectorAll('input[type="submit"], button[type="submit"]')
      .forEach((submitted) => {
        if (!submitted.hasAttribute('formaction')) {
          submitted.addEventListener('click', this.onRequestSubmit);
        }
      });
  }

  disconnect(): void {
    this.editor.destroy();

    if (this.fallback) {
      this.element.append(this.fallback);
      this.input.disabled = true;
    }

    if (!this.hasInputTarget) {
      this.input.remove();
    }

    this.toolbar.remove();

    this.element
      .closest('form')
      ?.querySelectorAll('input[type="submit"], button[type="submit"]')
      .forEach((submitted) =>
        submitted.removeEventListener('click', this.onRequestSubmit),
      );
  }

  private inflate(editor: Editor) {
    const fakeImageInput = document.createElement('input');
    fakeImageInput.setAttribute('aria-hidden', String(true));
    fakeImageInput.setAttribute('hidden', '');
    fakeImageInput.capture = 'environment';
    fakeImageInput.type = 'file';
    fakeImageInput.accept = 'image/*,.heif,.heic';
    fakeImageInput.multiple = true;
    fakeImageInput.addEventListener('change', (_) => {
      const files = fakeImageInput.files;

      // No file selected
      if (!files || files.length === 0) {
        return;
      }

      let hasAtLeastOne = false;
      let uploading = 0;

      for (let index = 0; index < files.length; index++) {
        const file = files.item(index);

        // Not an image
        if (!file) {
          console.warn('[tiptap] no file selected');
          continue;
        }

        if (!file.type.startsWith('image/')) {
          const extension = (file.name || '').split('.').pop()?.toLowerCase();
          if (!extension || !['heic', 'heif'].includes(extension)) {
            console.error('[tiptap] image unsupported', {
              extension,
              type: file.type,
            });
            continue;
          }
        }

        hasAtLeastOne = true;

        const uploader = document.createElement('div');
        uploader.setAttribute('role', 'progressbar');
        uploader.setAttribute('aria-valuemin', '0');
        uploader.setAttribute('aria-valuemax', '100');
        uploader.setAttribute('aria-valuenow', '0');
        uploader.setAttribute('aria-valuetext', 'Not started');
        uploader.classList.add(
          'absolute',
          'bottom-0',
          'left-0',
          'w-full',
          'bg-gray-200',
          'dark:bg-gray-800',
          'h-1',
          'overflow-hidden',
        );

        const bar = document.createElement('div');
        bar.classList.add(
          'bg-theme-primary-dark-500',
          'h-1',
          'w-full',
          'transition',
        );
        bar.style.transform = 'translateX(-100%)';

        uploader.append(bar);
        this.editorTarget.append(uploader);

        const upload = new AttachmentUpload(
          {
            file,
            setAttributes: console.debug,
            setUploadProgress: (progress, indeterminate) => {
              if (indeterminate) {
                uploader.removeAttribute('aria-valuenow');
                uploader.setAttribute('aria-valuetext', 'Uploading...');
              } else {
                uploader.setAttribute(
                  'aria-valuenow',
                  String(Math.round(progress)),
                );
                uploader.setAttribute(
                  'aria-valuetext',
                  String(Math.round(progress)) + '%',
                );

                const x = 100 - progress;
                bar.style.transform = `translateX(-${x}%)`;
              }

              if (progress === 100) {
                // uploader.remove();
              }
            },
          },
          this.directUploadUrlValue,
          this.blobUrlTemplateValue,
          this.representationUrlTemplateValue,
        );

        uploading += 1;

        editor.setEditable(false);

        upload
          .start()
          .then((attributes) => {
            uploading -= 1;

            if (uploading === 0) {
              editor.setEditable(true);
            }

            editor
              .chain()
              .focus()
              .setImage({
                src: attributes.url,
                sgid: attributes.sgid,
                url: attributes.url,
              })
              .run();

            uploader.remove();
          })
          .catch((error) => {
            uploading -= 1;

            if (uploading === 0) {
              editor.setEditable(true);
            }

            console.error(error);
            uploader.remove();
            throw error;
          });
      }
    });

    const fakeFileInput = document.createElement('input');
    fakeFileInput.setAttribute('aria-hidden', String(true));
    fakeFileInput.setAttribute('hidden', '');
    fakeFileInput.type = 'file';
    fakeFileInput.accept =
      'application/pdf, application/zip, text/*, audio/*, image/*, video/*, .heic, .heif, .pdf, .csv, .txt, .zip';

    fakeFileInput.addEventListener('change', (_) => {
      const files = fakeFileInput.files;

      // No file selected
      if (!files || files.length === 0) {
        return;
      }

      const file = files.item(0);

      // Not a file
      if (!file) {
        return;
      }

      if (!file) {
        console.warn('[tiptap] no file selected');
        return;
      }

      if (
        file.type != 'application/pdf' &&
        file.type !== 'application/zip' &&
        file.type !== 'application/x-zip-compressed' &&
        !file.type.startsWith('text/') &&
        !file.type.startsWith('video/') &&
        !file.type.startsWith('image/')
      ) {
        const extension = (file.name || '').split('.').pop()?.toLowerCase();
        if (!extension || !['heic', 'heif'].includes(extension)) {
          console.error('[tiptap] file unsupported', {
            extension,
            type: file.type,
          });
          return;
        }
      }

      const isMedia =
        file.type.startsWith('image/') ||
        ['heic', 'heif'].includes(
          (file.name?.split('.').pop() || '').toLocaleLowerCase(),
        );

      const uploader = document.createElement('div');
      uploader.setAttribute('role', 'progressbar');
      uploader.setAttribute('aria-valuemin', '0');
      uploader.setAttribute('aria-valuemax', '100');
      uploader.setAttribute('aria-valuenow', '0');
      uploader.setAttribute('aria-valuetext', 'Not started');
      uploader.classList.add(
        'absolute',
        'bottom-0',
        'left-0',
        'w-full',
        'bg-gray-200',
        'dark:bg-gray-800',
        'h-1',
        'overflow-hidden',
      );

      const bar = document.createElement('div');
      bar.classList.add(
        'bg-theme-primary-dark-500',
        'h-1',
        'w-full',
        'transition',
      );
      bar.style.transform = 'translateX(-100%)';

      uploader.append(bar);
      this.editorTarget.append(uploader);

      const upload = new AttachmentUpload(
        {
          file,
          setAttributes: console.debug,
          setUploadProgress: (progress, indeterminate) => {
            if (indeterminate) {
              uploader.removeAttribute('aria-valuenow');
              uploader.setAttribute('aria-valuetext', 'Uploading...');
            } else {
              uploader.setAttribute(
                'aria-valuenow',
                String(Math.round(progress)),
              );
              uploader.setAttribute(
                'aria-valuetext',
                String(Math.round(progress)) + '%',
              );

              const x = 100 - progress;
              bar.style.transform = `translateX(-${x}%)`;
            }

            if (progress === 100) {
              // uploader.remove();
            }
          },
        },
        this.directUploadUrlValue,
        this.blobUrlTemplateValue,
        this.representationUrlTemplateValue,
      );

      editor.setEditable(false);

      upload
        .start()
        .then((attributes) => {
          editor.setEditable(true);

          if (isMedia) {
            editor
              .chain()
              .focus()
              .setImage({
                src: attributes.url,
                sgid: attributes.sgid,
                url: attributes.url,
              })
              .run();
          } else {
            editor
              .chain()
              .focus()
              .setFile({
                sgid: attributes.sgid,
                url: attributes.url,
                caption: attributes.caption,
              })
              .run();
          }

          uploader.remove();
        })
        .catch((error) => {
          uploader.remove();
          console.error(error);
          throw error;
        });
    });

    let isExpanded = false;

    const buttons = [
      button('bold')
        .path(
          'M5.10505 12C4.70805 12 4.4236 11.912 4.25171 11.736C4.0839 11.5559 4 11.2715 4 10.8827V4.11733C4 3.72033 4.08595 3.43588 4.25784 3.26398C4.43383 3.08799 4.71623 3 5.10505 3C6.42741 3 8.25591 3 9.02852 3C10.1373 3 11.0539 3.98153 11.0539 5.1846C11.0539 6.08501 10.6037 6.81855 9.70327 7.23602C10.8657 7.44851 11.5176 8.62787 11.5176 9.48128C11.5176 10.5125 10.9902 12 9.27734 12C8.77742 12 6.42626 12 5.10505 12ZM8.37891 8.00341H5.8V10.631H8.37891C8.9 10.631 9.6296 10.1211 9.6296 9.29877C9.6296 8.47643 8.9 8.00341 8.37891 8.00341ZM5.8 4.36903V6.69577H8.17969C8.53906 6.69577 9.27734 6.35939 9.27734 5.50002C9.27734 4.64064 8.48047 4.36903 8.17969 4.36903H5.8Z',
        )
        .isActive((editor) => editor.isActive('bold'))
        .onClick((editor) => editor.chain().focus().toggleBold().run())
        .setVisibility('always')
        .build(editor),
      button('italic')
        .path(
          'M5.67494 3.50017C5.67494 3.25164 5.87641 3.05017 6.12494 3.05017H10.6249C10.8735 3.05017 11.0749 3.25164 11.0749 3.50017C11.0749 3.7487 10.8735 3.95017 10.6249 3.95017H9.00587L7.2309 11.05H8.87493C9.12345 11.05 9.32493 11.2515 9.32493 11.5C9.32493 11.7486 9.12345 11.95 8.87493 11.95H4.37493C4.1264 11.95 3.92493 11.7486 3.92493 11.5C3.92493 11.2515 4.1264 11.05 4.37493 11.05H5.99397L7.76894 3.95017H6.12494C5.87641 3.95017 5.67494 3.7487 5.67494 3.50017Z',
        )
        .isActive((editor) => editor.isActive('italic'))
        .onClick((editor) => editor.chain().focus().toggleItalic().run())
        .setVisibility('always')
        .build(editor),
      button('strike')
        .path(
          'M5.00003 3.25C5.00003 2.97386 4.77617 2.75 4.50003 2.75C4.22389 2.75 4.00003 2.97386 4.00003 3.25V7.10003H2.49998C2.27906 7.10003 2.09998 7.27912 2.09998 7.50003C2.09998 7.72094 2.27906 7.90003 2.49998 7.90003H4.00003V8.55C4.00003 10.483 5.56703 12.05 7.50003 12.05C9.43303 12.05 11 10.483 11 8.55V7.90003H12.5C12.7209 7.90003 12.9 7.72094 12.9 7.50003C12.9 7.27912 12.7209 7.10003 12.5 7.10003H11V3.25C11 2.97386 10.7762 2.75 10.5 2.75C10.2239 2.75 10 2.97386 10 3.25V7.10003H5.00003V3.25ZM5.00003 7.90003V8.55C5.00003 9.93071 6.11932 11.05 7.50003 11.05C8.88074 11.05 10 9.93071 10 8.55V7.90003H5.00003Z',
        )
        .isActive((editor) => editor.isActive('strike'))
        .onClick((editor) => editor.chain().focus().toggleStrike().run())
        .setVisibility('always')
        .build(editor),
      button('code')
        .path(
          'M9.96424 2.68571C10.0668 2.42931 9.94209 2.13833 9.6857 2.03577C9.4293 1.93322 9.13832 2.05792 9.03576 2.31432L5.03576 12.3143C4.9332 12.5707 5.05791 12.8617 5.3143 12.9642C5.5707 13.0668 5.86168 12.9421 5.96424 12.6857L9.96424 2.68571ZM3.85355 5.14646C4.04882 5.34172 4.04882 5.6583 3.85355 5.85356L2.20711 7.50001L3.85355 9.14646C4.04882 9.34172 4.04882 9.6583 3.85355 9.85356C3.65829 10.0488 3.34171 10.0488 3.14645 9.85356L1.14645 7.85356C0.951184 7.6583 0.951184 7.34172 1.14645 7.14646L3.14645 5.14646C3.34171 4.9512 3.65829 4.9512 3.85355 5.14646ZM11.1464 5.14646C11.3417 4.9512 11.6583 4.9512 11.8536 5.14646L13.8536 7.14646C14.0488 7.34172 14.0488 7.6583 13.8536 7.85356L11.8536 9.85356C11.6583 10.0488 11.3417 10.0488 11.1464 9.85356C10.9512 9.6583 10.9512 9.34172 11.1464 9.14646L12.7929 7.50001L11.1464 5.85356C10.9512 5.6583 10.9512 5.34172 11.1464 5.14646Z',
        )
        .isActive((editor) => editor.isActive('code'))
        .onClick((editor) => editor.chain().focus().toggleCode().run())
        .build(editor),
      button('paragraph')
        .path(
          'M3 5.5C3 7.983 4.99169 9 7 9V12.5C7 12.7761 7.22386 13 7.5 13C7.77614 13 8 12.7761 8 12.5V9V3.1H9V12.5C9 12.7761 9.22386 13 9.5 13C9.77614 13 10 12.7761 10 12.5V3.1H11.5C11.8038 3.1 12.05 2.85376 12.05 2.55C12.05 2.24624 11.8038 2 11.5 2H9.5H8H7.5H7C4.99169 2 3 3.017 3 5.5Z',
        )
        .isActive((editor) => editor.isActive('paragraph'))
        .isDisabled((editor) => editor.isActive('paragraph'))
        .onClick((editor) => editor.chain().focus().setParagraph().run())
        .build(editor),
      button('h1')
        .isActive((editor) => editor.isActive('heading', { level: 1 }))
        .onClick((editor) =>
          editor.chain().focus().toggleHeading({ level: 1 }).run(),
        )
        .build(editor),
      button('h2')
        .isActive((editor) => editor.isActive('heading', { level: 2 }))
        .onClick((editor) =>
          editor.chain().focus().toggleHeading({ level: 2 }).run(),
        )
        .build(editor),
      button('h3')
        .isActive((editor) => editor.isActive('heading', { level: 3 }))
        .onClick((editor) =>
          editor.chain().focus().toggleHeading({ level: 3 }).run(),
        )
        .build(editor),
      button('h4')
        .isActive((editor) => editor.isActive('heading', { level: 4 }))
        .onClick((editor) =>
          editor.chain().focus().toggleHeading({ level: 4 }).run(),
        )
        .build(editor),
      button('bullet list')
        .path(
          'M1.5 5.25C1.91421 5.25 2.25 4.91421 2.25 4.5C2.25 4.08579 1.91421 3.75 1.5 3.75C1.08579 3.75 0.75 4.08579 0.75 4.5C0.75 4.91421 1.08579 5.25 1.5 5.25ZM4 4.5C4 4.22386 4.22386 4 4.5 4H13.5C13.7761 4 14 4.22386 14 4.5C14 4.77614 13.7761 5 13.5 5H4.5C4.22386 5 4 4.77614 4 4.5ZM4.5 7C4.22386 7 4 7.22386 4 7.5C4 7.77614 4.22386 8 4.5 8H13.5C13.7761 8 14 7.77614 14 7.5C14 7.22386 13.7761 7 13.5 7H4.5ZM4.5 10C4.22386 10 4 10.2239 4 10.5C4 10.7761 4.22386 11 4.5 11H13.5C13.7761 11 14 10.7761 14 10.5C14 10.2239 13.7761 10 13.5 10H4.5ZM2.25 7.5C2.25 7.91421 1.91421 8.25 1.5 8.25C1.08579 8.25 0.75 7.91421 0.75 7.5C0.75 7.08579 1.08579 6.75 1.5 6.75C1.91421 6.75 2.25 7.08579 2.25 7.5ZM1.5 11.25C1.91421 11.25 2.25 10.9142 2.25 10.5C2.25 10.0858 1.91421 9.75 1.5 9.75C1.08579 9.75 0.75 10.0858 0.75 10.5C0.75 10.9142 1.08579 11.25 1.5 11.25Z',
        )
        .isActive((editor) => editor.isActive('bulletList'))
        .onClick((editor) => editor.chain().focus().toggleBulletList().run())
        .build(editor),
      button('blockquote')
        .path(
          'M9.42503 3.44136C10.0561 3.23654 10.7837 3.2402 11.3792 3.54623C12.7532 4.25224 13.3477 6.07191 12.7946 8C12.5465 8.8649 12.1102 9.70472 11.1861 10.5524C10.262 11.4 8.98034 11.9 8.38571 11.9C8.17269 11.9 8 11.7321 8 11.525C8 11.3179 8.17644 11.15 8.38571 11.15C9.06497 11.15 9.67189 10.7804 10.3906 10.236C10.9406 9.8193 11.3701 9.28633 11.608 8.82191C12.0628 7.93367 12.0782 6.68174 11.3433 6.34901C10.9904 6.73455 10.5295 6.95946 9.97725 6.95946C8.7773 6.95946 8.0701 5.99412 8.10051 5.12009C8.12957 4.28474 8.66032 3.68954 9.42503 3.44136ZM3.42503 3.44136C4.05614 3.23654 4.78366 3.2402 5.37923 3.54623C6.7532 4.25224 7.34766 6.07191 6.79462 8C6.54654 8.8649 6.11019 9.70472 5.1861 10.5524C4.26201 11.4 2.98034 11.9 2.38571 11.9C2.17269 11.9 2 11.7321 2 11.525C2 11.3179 2.17644 11.15 2.38571 11.15C3.06497 11.15 3.67189 10.7804 4.39058 10.236C4.94065 9.8193 5.37014 9.28633 5.60797 8.82191C6.06282 7.93367 6.07821 6.68174 5.3433 6.34901C4.99037 6.73455 4.52948 6.95946 3.97725 6.95946C2.7773 6.95946 2.0701 5.99412 2.10051 5.12009C2.12957 4.28474 2.66032 3.68954 3.42503 3.44136Z',
        )
        .isActive((editor) => editor.isActive('blockquote'))
        .onClick((editor) => editor.chain().focus().toggleBlockquote().run())
        .build(editor),
      button('attach image')
        .path(
          'M2.5 1H12.5C13.3284 1 14 1.67157 14 2.5V12.5C14 13.3284 13.3284 14 12.5 14H2.5C1.67157 14 1 13.3284 1 12.5V2.5C1 1.67157 1.67157 1 2.5 1ZM2.5 2C2.22386 2 2 2.22386 2 2.5V8.3636L3.6818 6.6818C3.76809 6.59551 3.88572 6.54797 4.00774 6.55007C4.12975 6.55216 4.24568 6.60372 4.32895 6.69293L7.87355 10.4901L10.6818 7.6818C10.8575 7.50607 11.1425 7.50607 11.3182 7.6818L13 9.3636V2.5C13 2.22386 12.7761 2 12.5 2H2.5ZM2 12.5V9.6364L3.98887 7.64753L7.5311 11.4421L8.94113 13H2.5C2.22386 13 2 12.7761 2 12.5ZM12.5 13H10.155L8.48336 11.153L11 8.6364L13 10.6364V12.5C13 12.7761 12.7761 13 12.5 13ZM6.64922 5.5C6.64922 5.03013 7.03013 4.64922 7.5 4.64922C7.96987 4.64922 8.35078 5.03013 8.35078 5.5C8.35078 5.96987 7.96987 6.35078 7.5 6.35078C7.03013 6.35078 6.64922 5.96987 6.64922 5.5ZM7.5 3.74922C6.53307 3.74922 5.74922 4.53307 5.74922 5.5C5.74922 6.46693 6.53307 7.25078 7.5 7.25078C8.46693 7.25078 9.25078 6.46693 9.25078 5.5C9.25078 4.53307 8.46693 3.74922 7.5 3.74922Z',
        )
        .onClick(() => fakeImageInput.click())
        .setVisibility('always')
        .build(editor),
      button('attach file')
        .path(
          'M3.5 2C3.22386 2 3 2.22386 3 2.5V12.5C3 12.7761 3.22386 13 3.5 13H11.5C11.7761 13 12 12.7761 12 12.5V4.70711L9.29289 2H3.5ZM2 2.5C2 1.67157 2.67157 1 3.5 1H9.5C9.63261 1 9.75979 1.05268 9.85355 1.14645L12.7803 4.07322C12.921 4.21388 13 4.40464 13 4.60355V12.5C13 13.3284 12.3284 14 11.5 14H3.5C2.67157 14 2 13.3284 2 12.5V2.5ZM4.75 7.5C4.75 7.22386 4.97386 7 5.25 7H7V5.25C7 4.97386 7.22386 4.75 7.5 4.75C7.77614 4.75 8 4.97386 8 5.25V7H9.75C10.0261 7 10.25 7.22386 10.25 7.5C10.25 7.77614 10.0261 8 9.75 8H8V9.75C8 10.0261 7.77614 10.25 7.5 10.25C7.22386 10.25 7 10.0261 7 9.75V8H5.25C4.97386 8 4.75 7.77614 4.75 7.5Z',
        )
        .onClick(() => fakeFileInput.click())
        .setVisibility('always')
        .build(editor),
      button('ordered list')
        .isActive((editor) => editor.isActive('orderedList'))
        .onClick((editor) => editor.chain().focus().toggleOrderedList().run())
        .build(editor),
      button('code block')
        .isActive((editor) => editor.isActive('codeBlock'))
        .onClick((editor) => editor.chain().focus().toggleCodeBlock().run())
        .build(editor),
      /*button('horizontal rule')
        .onClick((editor) => editor.chain().focus().setHorizontalRule().run())
        .build(editor),*/
      button('line break')
        .onClick((editor) => editor.chain().focus().setHardBreak().run())
        .build(editor),
      button('undo action')
        .onClick((editor) => editor.chain().focus().undo().run())
        .build(editor),
      button('redo action')
        .onClick((editor) => editor.chain().focus().redo().run())
        .build(editor),
      button('formatting')
        .path(
          'M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z',
          '0 0 20 20',
        )
        .setVisibility('always')
        .isActive(() => false)
        .classList('--formatter')
        .onClick((_, button) => {
          isExpanded = !isExpanded;
          toggleExpanded(this.toolbar, isExpanded);
          toggleAttribute('aria-pressed', button, isExpanded, [
            'true',
            'false',
          ]);
        })
        .build(editor),
    ];

    this.toolbar = document.createElement('div');
    this.toolbar.classList.add(
      'tiptap-toolbar',
      'group',
      'print:hidden',
      'not-prose',
    );
    this.toolbar.setAttribute('role', 'toolbar');
    this.toolbar.setAttribute('aria-controls', this.editorTarget.id);
    this.toolbar.append(...buttons);

    if (this.hasReadonlyValue && this.readonlyValue) {
      toggleHidden(this.toolbar, true);
    }

    this.element.prepend(this.toolbar);
  }

  private onUpdate(props: unknown): void {
    clearTimeout(this.onUpdateTimer as any);

    // Debounce updates
    this.onUpdateTimer = setTimeout(() => {
      this.input.value = this.editor.getHTML();
    }, DEBOUNCE_UPDATES);
  }

  private onRequestSubmit(event: Event): void {
    if (!this.debounce) {
      this.debounce = true;
      return;
    }

    event.preventDefault();

    const input = event.currentTarget as HTMLInputElement | HTMLButtonElement;
    input.disabled = true;

    clearTimeout(this.onUpdateTimer as any);

    this.onUpdateTimer = setTimeout(() => {
      input.disabled = false;
      this.input.value = this.editor.getHTML();

      if (
        requestSubmit(
          input.form,
          event.currentTarget as HTMLInputElement | HTMLButtonElement,
        )
      ) {
        this.debounce = false;
      }
    }, DEBOUNCE_UPDATES + 1);
  }
}

function button(label: string) {
  return new ButtonBuilder().label(label);
}

class ButtonBuilder {
  private labelValue: undefined | string;
  private iconPathValue: undefined | string;
  private iconViewBox: undefined | string;
  private iconFill: undefined | boolean;
  private iconStroke: undefined | boolean;
  private additionClassNames: undefined | string[];
  private isActiveValue: undefined | ((editor: Editor) => boolean);
  private isDisabledValue: undefined | ((editor: Editor) => boolean);
  private onClickValue:
    | undefined
    | ((editor: Editor, button: HTMLButtonElement) => void);
  private visibility: undefined | 'always' | 'formatting';

  constructor() {}

  public path(
    next: string,
    viewBox = '0 0 15 15',
    fill = true,
    stroke = false,
  ) {
    this.iconPathValue = next;
    this.iconViewBox = viewBox;
    this.iconFill = fill;
    this.iconStroke = stroke;

    return this;
  }

  public label(next: string) {
    this.labelValue = next;
    return this;
  }

  public isActive(next: undefined | ((editor: Editor) => boolean)) {
    this.isActiveValue = next;
    return this;
  }

  public isDisabled(next: undefined | ((editor: Editor) => boolean)) {
    this.isDisabledValue = next;
    return this;
  }

  public classList(...params: string[]) {
    this.additionClassNames = params;
    return this;
  }

  public onClick(
    next: undefined | ((editor: Editor, button: HTMLButtonElement) => void),
  ) {
    this.onClickValue = next;
    return this;
  }

  public setVisibility(visibility: 'always' | 'formatting') {
    this.visibility = visibility;
    return this;
  }

  public build(editor: Editor) {
    const node = document.createElement('button');
    node.type = 'button';
    node.textContent = this.labelValue || '(button)';
    node.disabled = !this.onClickValue;
    node.classList.add(
      'button',
      '--xs',
      '--input',
      'group',
      ...(this.additionClassNames ?? []),
    );

    if (this.iconPathValue && this.iconViewBox) {
      const figure = document.createElement('figure');
      figure.classList.add('flex', 'items-center');

      const icon = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg',
      );
      icon.setAttributeNS(null, 'viewBox', this.iconViewBox);
      icon.setAttributeNS(null, 'width', '15');
      icon.setAttributeNS(null, 'height', '15');
      icon.setAttributeNS(null, 'fill', 'none');

      const iconPath = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path',
      );
      iconPath.setAttributeNS(null, 'd', this.iconPathValue);
      iconPath.setAttributeNS(
        null,
        'fill',
        this.iconFill ? 'currentColor' : 'none',
      );
      iconPath.setAttributeNS(
        null,
        'stroke',
        this.iconStroke ? 'currentColor' : 'none',
      );
      iconPath.setAttributeNS(null, 'fill-rule', 'evenodd');
      iconPath.setAttributeNS(null, 'clip-rule', 'evenodd');

      icon.appendChild(iconPath);

      const figcaption = document.createElement('figcaption');
      figcaption.textContent = node.textContent;
      figcaption.classList.add(
        'sr-only',
        'sm:not-sr-only',
        'sm:pl-2',
        // 'group-focus-visible:not-sr-only',
        // 'group-focus-visible:ml-2'
      );

      figure.append(icon, figcaption);

      node.textContent = '';
      node.append(figure);
    }

    if (this.visibility !== 'always') {
      node.classList.add('--formatting');
    }

    if (this.isActiveValue) {
      const isActiveValueFn = this.isActiveValue;

      let debounceTimer: unknown = undefined;
      const onUpdate = () => {
        clearTimeout(debounceTimer as any);
        debounceTimer = setTimeout(refresh, 150);
      };

      const refresh = () => {
        const next = isActiveValueFn(editor);
        node.classList.toggle('is-active', next);
        node.setAttribute('aria-pressed', String(next));

        // TODO: isDisabledValue
      };

      editor.on('selectionUpdate', onUpdate);
      editor.on('update', onUpdate);
    }

    if (this.onClickValue) {
      const onClickValueFn = this.onClickValue;
      node.addEventListener('click', () => onClickValueFn(editor, node));
    }

    return node;
  }
}
